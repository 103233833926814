import AbstractComponent from './abstractComponent';
import 'slick-carousel';

export default class NavigableContainer extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);

        if (this.exists) {
            this.initTabs();
            this.initAccordion();
            this.initMobileSlider();
            $(window).on('resize', () => {
                this.initMobileSlider();
            });
        }
    }

    initMobileSlider() {
        const imageRow = $(this.selector).find('.image-row'); // Scope it to the instance's selector
        const isMobile = $(this.selector).find('.mobile').length > 0;

        if (isMobile && $(window).width() <= 991) {
            if (!imageRow.hasClass('slick-initialized')) {
                imageRow.slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: true,
                    infinite: false,
                    adaptiveHeight: false,
                });
            }
        } else if (imageRow.hasClass('slick-initialized')) {
            imageRow.slick('unslick');
        }
    }

    static reinitializeSlider(imageRow) {
        if ($(imageRow).hasClass('slick-initialized')) {
            $(imageRow).slick('unslick');
        }

        $(imageRow).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            infinite: false,
            adaptiveHeight: false,
        });
    }

    initTabs() {
        const tabItems = $(this.selector).find('.navigable__tabs ul li');
        const tabContents = $(this.selector).find('.navigable__tab-content .tab-content');

        if (tabItems.length === 0 || tabContents.length === 0) return;

        tabItems.each(function () {
            $(this).on('click', function () {
                const tabId = $(this).data('tab');

                tabItems.removeClass('active');
                tabContents.removeClass('active');

                $(this).addClass('active');
                $(`#${tabId}`).addClass('active');
            });
        });

        // Initialize first tab as active
        tabItems.first().addClass('active');
        tabContents.first().addClass('active');
    }

    initAccordion() {
        const accordionItems = $(this.selector).find('.accordion-item');
        const accordionContents = $(this.selector).find('.accordion-content');

        if (accordionItems.length === 0 || accordionContents.length === 0) return;

        accordionItems.each((index, item) => {
            $(item).on('click', () => {
                const isActive = $(item).hasClass('active');

                // Reset all accordion items
                accordionItems.removeClass('active').find('.minus-icon').hide().siblings('.plus-icon').show();
                accordionContents.removeClass('active');

                if (!isActive) {
                    $(item).addClass('active').find('.minus-icon').show().siblings('.plus-icon').hide();
                    accordionContents.eq(index).addClass('active');

                    const activeImageRow = accordionContents.eq(index).find('.image-row');
                    if (activeImageRow.length > 0) {
                        NavigableContainer.reinitializeSlider(activeImageRow);
                    }
                }
            });
        });

        // Set first accordion as active
        accordionItems.first().addClass('active').find('.minus-icon').show().siblings('.plus-icon').hide();
        accordionContents.first().addClass('active');

        const initialImageRow = accordionContents.first().find('.image-row');
        if (initialImageRow.length > 0) {
            NavigableContainer.reinitializeSlider(initialImageRow);
        }
    }
}
