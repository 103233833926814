import AbstractComponent from './abstractComponent';

export default class Questionnaire extends AbstractComponent {
    constructor(GLOBALS, selector) {
        super(GLOBALS, selector);
        function createChart(answerData) {
            const labelData = [];
            const chartData = [];

            Object.entries(answerData).forEach(entry => {
                const [key, value] = entry;
                labelData.push(key);
                chartData.push(value.average);
            });
            const ctx = document.getElementById('myChart');
            /* global Chart, ChartDataLabels */
            Chart.register(ChartDataLabels);
            /* eslint-disable no-new */

            new Chart(ctx, {
                type: 'polarArea',
                data: {
                    labels: labelData,
                    datasets: [{
                        backgroundColor: ['#F4C5A0', '#8FDEBC', '#E3BEF9', '#83909E', '#606060'],
                        data: chartData
                    }]
                },
                plugins: [ChartDataLabels],
                options: {
                    plugins: {
                        datalabels: {
                            anchor: 'end',
                            backgroundColor(context) {
                                return context.dataset.backgroundColor;
                            },
                            borderColor: 'white',
                            borderRadius: 50,
                            borderWidth: 2,
                            color: '#1a1a1a',
                            font: {
                                weight: 'bold'
                            },
                            padding: 10,
                        },
                        legend: {
                            display: false
                        },
                    },

                    // Core options
                    aspectRatio: 4 / 3,
                    layout: {
                        padding: 0
                    },
                    elements: {
                        line: {
                            fill: true
                        },
                        point: {
                            hoverRadius: 7,
                            radius: 10
                        }
                    },
                }
            });
        }
        if (this.exists) {
            /* eslint-disable-next-line prefer-arrow-callback */
            const formsubmit = $('.questionnaireTopSection').data('formsubmit');
            if( formsubmit ) {
                const answerData = $('.questionnaireTopSection').data('answer');
                createChart(answerData.category);
            }

            $(() => {
                $('.questionnaire').hide();
                $('.level-1').show();
                $('.level-1 .prevQuiz').hide();
                $('.submitQuiz').hide();
            
                $('.menulevel-1').addClass('active');

                if($('div').hasClass('questionnaireResultShow'))
                    $('.questionnaireResult').show();

                function updateActiveMenu(levelKey) {
                    $('.questionnaire-menu-links').removeClass('done active');
            
                    $('.questionnaire-menu-links').each(function () {
                        const currentMenuKey = $(this).attr('class').match(/menulevel-(\d+)/)[1];
            
                        if (currentMenuKey < levelKey) {
                            $(this).addClass('done');
                        } else if (currentMenuKey === levelKey) {
                            $(this).addClass('active');
                        }
                    });
                }
            
                $('.nextQuiz').on('click', function () {
                    $('html, body').animate({
                        scrollTop: $('#topOfQuestionnaire').offset().top
                    }, 'slow');

                    const currentLevel = $(this).closest('.questionnaire');
                    const nextLevel = currentLevel.next('.questionnaire');
                    
                    if (nextLevel.length) {
                        const nextLevelKey = nextLevel.attr('class').match(/level-(\d+)/)[1];
                        currentLevel.hide();
                        nextLevel.show();
                        updateActiveMenu(nextLevelKey);
                    }
            
                    nextLevel.find('.prevQuiz').show();
                    nextLevel.find('.nextQuiz').show();
                    $('.submitQuiz').hide();
            
                    if (!nextLevel.next('.questionnaire').length) {
                        nextLevel.find('.nextQuiz').hide();
                        nextLevel.find('.submitQuiz').show();
                    }
                });
            
                $('.prevQuiz').on('click', function () {
                    $('html, body').animate({
                        scrollTop: $('#topOfQuestionnaire').offset().top
                    }, 'slow');

                    const currentLevel = $(this).closest('.questionnaire');
                    const prevLevel = currentLevel.prev('.questionnaire');
                    
                    if (prevLevel.length) {
                        const prevLevelKey = prevLevel.attr('class').match(/level-(\d+)/)[1];
                        currentLevel.hide();
                        prevLevel.show();
                        updateActiveMenu(prevLevelKey);
                    }
            
                    prevLevel.find('.nextQuiz').show();
                    prevLevel.find('.submitQuiz').hide();
            
                    if (!prevLevel.prev('.questionnaire').length) {
                        prevLevel.find('.prevQuiz').hide();
                    }
                });
            
                /* eslint-disable-next-line prefer-arrow-callback */
                $('.submitQuiz').on('click', function () {
                    $('html, body').animate({
                        scrollTop: $('#topOfQuestionnaire').offset().top
                    }, 'slow');

                    $('.questionnaire').hide();
                    $('.questionnaireTopSection .des').hide();
                    $('.questionnaireResult').show();
                    const quizData = {};
                    const questionnaireId = $('.questionnaireTopSection').data('questionnaire');
        
                    $(".questionnaire-quiz").each(function () {
                        const level = $(this).closest(".questionnaire").attr("class").match(/level-(\d+)/)[1]; // Extract level

                        quizData[level] = quizData[level] || {};
            
                        $(this).find(".questionnaire-quiz-title").each(function () {
                            const questionId = $(this).attr("class").match(/quiz-(\d+)/)[1]; // Extract the question ID
                            const selectedOption = $(`input[name="quiz-${questionId}"]:checked`).val(); // Get selected answer
            
                            if (selectedOption) {
                                quizData[questionId] = selectedOption;
                            }
                        });
                    });

                    $.ajax({
                        url: '/api/v1/questionnaire/save',
                        method: 'POST',
                        data: {
                            "answer": quizData,
                            "questionnaire": questionnaireId
                        },
                        success(response) {
                            $("#questionnaireFinalResult").html(response.html);
                            createChart(response.answerData.category)
                        }
                    });
                });
            });
        }
    }
}
